import _ from 'lodash';

export default {
    data () {
        return {
            errorMessages: {}
        };
    },
    methods: {
        setFormErrors (ex) {
            this.errorMessages = ex.errorMessages;
        },
        resetFormErrors () {
            this.errorMessages = {};
        },
        hasError (field) {
            var message = this.getError(field);
            return message && Boolean(message);
        },
        getError (field) {
            var f = field.toLocaleLowerCase();

            var errorMessage = _.find(this.errorMessages, (msg, key) => {
                return key.toLocaleLowerCase() === f;
            });
            return errorMessage;
        },
        resetError (field) {
            var f = field.toLocaleLowerCase();
            var errorKey = _.findKey(this.errorMessages, (msg, key) => {
                return key.toLocaleLowerCase() === f;
            });
            if (errorKey) {
                this.errorMessages[errorKey] = undefined;
            }
        }
    }
};
